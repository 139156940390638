import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState, VFC } from 'react'
import { Avatar, Box, Button, Flex, HStack, Image, Link, Show, Text, useBreakpointValue } from '@chakra-ui/react'
import { useVideo, useAudio } from '@actualinc/whereness_player_package'

import { StartIcon } from '../atoms/icon/StartIcon'
import { usePage } from '../../hooks/usePage'
import logoImg from '../../images/logo_whereness_set.svg'
import iframeLogoImage from '../../images/logo_whereness_black_set.svg'
import dummyImg from '../../images/img_dummy.svg'
import { useDateFormat } from '../../hooks/useDateFormat'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SignedImage } from '../atoms/ui/SignedImage'
import { motion } from 'framer-motion'
import { config } from '../../config'
import { ExpandableText } from '../molecules/ExpandableText'
import { Headline1 } from '../atoms/text/Headline1'
import { Body1 } from '../atoms/text/Body1'
import { useIsAutoPlay } from '../../hooks/providers/useIsAutoPlay'
import { useViewingHistory } from '../../hooks/useViewingHistory'
import { useActiveNumber } from '../../hooks/useActiveNumber'
import { LockIcon } from '../atoms/icon/LockIcon'
import { SIDEBAR_COLOR_THEME } from '../../config/sidebarColorTheme'
import { PlayIcon } from '../atoms/icon/PlayIcon'

type LandingType = {
  onLoaded: () => void
}

export const Landing: VFC<LandingType> = memo((props) => {
  const { sidebarColorTheme } = usePlayerData()
  const { onLoaded } = props
  const [bgHeight, setBgHeight] = useState(0)
  const [isIframe, setIsIframe] = useState(false)
  const refContents = useRef(null)
  const { video } = useVideo()
  const { audio } = useAudio()
  const { page, setPage } = usePage()
  const { dateFormat } = useDateFormat()
  const { projectInfoData, projectPublishId, sceneInfoData } = usePlayerData() // Project Info

  const { addHistory } = useViewingHistory()
  const { activeSceneId } = useActiveNumber()
  const { setStartButton } = useIsAutoPlay()
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const isTablet = useBreakpointValue({ base: true, md: false })
  const getLandingStyles = (sidebarColorTheme: string) => {
    switch (sidebarColorTheme) {
      case SIDEBAR_COLOR_THEME.BLACK:
        return {
          bgColor: 'black.900',
          contentColor: 'white.800',
          hoverIconColor: 'gray.800',
          outlineColor: '#4A5568',
          boxShadowColor: 'rgba(74, 85, 104, 0.6)',
          baseBgColor: '#000000',
          baseTextColor: 'white.800',
          baseIconBg: 'gray.800',
          wherenessIconFilter: 'none',
          baseHoverIconColor: 'gray.700',
        }
      case SIDEBAR_COLOR_THEME.WHITE:
        return {
          bgColor: 'white.900',
          contentColor: 'black.800',
          hoverIconColor: 'gray.200',
          outlineColor: '#E2E8F0',
          boxShadowColor: 'rgba(226, 232, 240, 0.6)',
          baseBgColor: 'white',
          baseTextColor: 'black.800',
          baseIconBg: 'gray.300',
          wherenessIconFilter: 'invert(1)',
          baseHoverIconColor: 'gray.200',
        }
      case SIDEBAR_COLOR_THEME.GRAY:
        return {
          bgColor: 'gray.200',
          contentColor: 'black.800',
          hoverIconColor: 'white.900',
          outlineColor: '#F7FAFC',
          boxShadowColor: 'rgba(247, 250, 252, 0.6)',
          baseBgColor: 'gray.200',
          baseTextColor: 'black.800',
          baseIconBg: 'white.900',
          wherenessIconFilter: 'invert(1)',
          baseHoverIconColor: 'white.600',
        }
      default:
        return {
          bgColor: 'blue.500',
          contentColor: 'white.800',
          hoverIconColor: 'blue.450',
          outlineColor: '#3182ce',
          boxShadowColor: 'rgba(56, 189, 248, 0.6)',
          baseBgColor: 'gray.800',
          baseTextColor: 'white.800',
          baseIconBg: 'blue.500',
          wherenessIconFilter: 'none',
          baseHoverIconColor: 'blue.450',
        }
    }
  }
  const currentStyle = getLandingStyles(sidebarColorTheme)
  // SP時　背景画像の高さを設定
  useLayoutEffect(() => {
    if (isMobile) {
      if (refContents.current) {
        setBgHeight(window.innerHeight - refContents.current.offsetHeight)
      }
    }
  }, [isMobile, refContents])

  // Startボタンクリック
  const onClickStart = useCallback(
    (e) => {
      setStartButton(true)
      if (sceneInfoData?.contextType === 'video') {
        video.play()
      }
      if (sceneInfoData?.bgmUrl) {
        audio.play()
      }
      setPage('space')
      addHistory(activeSceneId)
    },
    [video, setPage, activeSceneId]
  )

  const showAvatar = useCallback(() => {
    if (!projectInfoData.options) {
      return true
    }
    if (!projectInfoData.options.hasOwnProperty('hideCreatedBy')) {
      return true
    }
    return !projectInfoData.options.hideCreatedBy
  }, [projectInfoData])

  useEffect(() => {
    const checkDimenssions = () => {

      if( window.top !== window.self){
        setIsIframe(true)
      }else{
        setIsIframe(false)
      }
    }
    checkDimenssions()
    window.addEventListener('resize', checkDimenssions)
    return () => {
      window.removeEventListener('resize', checkDimenssions)
    }
  },[setIsIframe])



  return (
    <>
      <Box
        as={motion.div}
        width="100%"
        h="calc(var(--player-vh, 1vh) * 100)"
        position="absolute"
        top="0"
        left="0"
        zIndex="landing"
        key="landing"
        opacity="1"
        initial={{ opacity: 1 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: config.FADE_OUT_SM.TIME,
          ease: config.FADE_OUT_SM.EASE,
        }}
      >
        <Box w="100%" h="calc(var(--player-vh, 1vh) * 100)" position="absolute" top="0" left="0">
          {projectInfoData?.previewUrl === null ? (
            <Image
              src={dummyImg}
              w="100%"
              h="calc(var(--player-vh, 1vh) * 100)"
              objectFit="cover"
              position="absolute"
              zIndex="landingInner"
              top="0"
              left="0"
              onLoad={onLoaded}
            />
          ) : (
            <SignedImage
              w="100%"
              h={{ base: !isIframe ? `${bgHeight + 30}px` : '100%', sm:'calc(var(--player-vh, 1vh) * 100)' }}
              objectFit="cover"
              src={projectInfoData?.previewUrl}
              position="absolute"
              zIndex="landingImage"
              top="0"
              left="0"
              onLoaded={onLoaded}
            />
          )}

          <Box
            bg="radial-gradient(153.42% 64.56% at 50% 35.44%, rgba(0, 0, 0, 0) 17.71%, rgba(0, 0, 0, 0.4) 60.94%)"
            w="100%"
            h="calc(var(--player-vh, 1vh) * 100)"
            position="absolute"
            zIndex="landingImage"
            top="0"
            left="0"
            display={{ base: 'none', sm: 'block' }}
          />

          <Box 
          ref={refContents} 
          width="100%" 
          mx={{ md: 'auto' }} 
          position="absolute" 
          zIndex="landingImage"
          {...(isIframe ? { top: 0, h: '100%' } : { bottom: 0 })}
          >
            <Flex
              w={{ base: '100%', md: !isIframe ? '88%' : '100%', lg: '100%' }}
              flexDirection={{ base: 'column', md: !isIframe ?  'row' : 'column' }}
              backgroundColor={{ base: isIframe ? 'transparent' : currentStyle.baseBgColor, sm: 'transparent' }}
              p={{ base: !isIframe ? '28px 16px 22px 16px' : '0px 32px 0px 32px', sm: !isIframe ? '0 32px 64px 32px' : '0px 32px 0px 32px', md:!isIframe? '0 0 48px' : '0 32px', lg: '0 96px 64px 72px' }}
              borderRadius={{ base: '16px 16px 0 0', sm: '0' }}
              m={{ md: '0 auto', lg: '0' }}
              alignItems={{ md: 'center' }}
              justifyContent={{base: isIframe ? 'space-between' : 'flex-start', md: 'space-between' }}
              {... (isIframe && {h: '100%'} )}
            >
              <Box
                mb={{ base: '31px', md: '0' }}
                flexShrink={{ md: 0 }}
                {
                  ...isIframe && 
                  (
                    {
                      w:{ md: '100%', lg: '100%' },
                      maxWidth:{ md: '100%', lg: '100%' },
                      mr: { md: '0', lg: '0' },
                    }
                  )
                }
                {
                  ...!isIframe && 
                  (
                    {
                      w:{ md: '57%', lg: '60%' },
                      maxWidth:{ md: '60%', lg: '640px' },
                      mr: { md: '32px', lg: '48px' },

                    }
                  )
                }
                
                textColor={{ base: currentStyle.baseTextColor, sm: 'unset' }}
              >
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <Headline1 as="h1" { ...(isIframe && {fontSize: window.innerWidth>=960 ? 'sm': 'xs'})}>{projectInfoData?.title}</Headline1>
                  {projectInfoData?.publishStatus === 'password' ? (
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '16px',
                        padding: '8px',
                        background: 'rgba(0, 0, 0, 0.06)',
                      }}
                    >
                      <LockIcon style={{ height: '24px', width: '24px' }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {projectInfoData?.description && !isIframe && (
                  <Box whiteSpace="break-spaces" mt={{ base: '10px', md: '26px' }}>
                    {isTablet ? (
                      <ExpandableText text={projectInfoData?.description} />
                    ) : (
                      <Body1>
                        <Text>{projectInfoData?.description}</Text>
                      </Body1>
                    )}
                  </Box>
                )}
              </Box>
              { isIframe &&
                 <Box w={'100%'} display={'flex'} justifyContent={'center'}>
                 <Button
                 onClick={onClickStart}
                 variant="solid"
                 size={window.innerWidth >= 960 ? "md" : "sm"}
                 display="flex"          
                 alignItems="center"      
                 justifyContent="center"  
                 iconSpacing={0}
                 bg="white"
                 w={window.innerWidth >= 960 ? '72px' : '40px'}
                 h={window.innerWidth >= 960 ? '72px' : '40px'}
                 _hover={{bg: 'gray.100'}}
                 _focus={{ bg: 'white', boxShadow: 'none' }} 
                 _active={{ bg: 'gray.200' }}
                 leftIcon={<PlayIcon boxSize={{ base: window.innerWidth >= 960 ? '40px' : '25px' }} color={'black'} />}
               >
                
               </Button>
               </Box>
              }
              <Box 
                {
                  ...!isIframe && 
                  (
                    {
                      w:{md: '40%'},
                      maxWidth:{md:'360px'},
                      flexShrink: {md: 0}
                    }
                  )
                }
              >
                {!isIframe && <Button
                  onClick={onClickStart}
                  bg={{ base: currentStyle.baseIconBg, sm: currentStyle.bgColor }}
                  color={currentStyle.contentColor}
                  variant="solid"
                  size="lg"
                  _hover={{ bg: { base: currentStyle.baseHoverIconColor, sm: currentStyle.hoverIconColor } }}
                  _active={{ bg: currentStyle.hoverIconColor }}
                  _focus={{
                    outline: '2px solid ', // Blue outline (default focus ring color)
                    outlineColor: currentStyle.outlineColor,
                    boxShadow: `0 0 0 3px ${currentStyle.boxShadowColor}`, // Light blue glow (default focus effect)
                  }}
                  leftIcon={<StartIcon fill={currentStyle.contentColor} boxSize={{ base: '25px', sm: '33px' }} />}
                >
                  Start
                </Button> 
                }
                <Box display={'flex'} justifyContent={'center'}>
                  <Link href='https://hello.whereness.io/' target='_blank'>
                    <Image
                      filter={{ base: currentStyle.wherenessIconFilter, sm: 'none' }}
                      src={logoImg}
                      {
                        ...
                        (isIframe &&
                          {
                            w:{base: window.innerWidth >= 960 ? '109px' : '85.75px' },
                            h:{ base: window.innerWidth >= 960 ? '32px' : '24px'}
                          }
                        )
                      }
                      {...
                        (!isIframe &&
                          {
                            position:{ md: 'fixed' },
                            top:{ md: '0' },
                            right:{ md: '0' },
                            w:{ base: '180px', md: '219px' },
                            h:{ base: '46px', md: '56px' }
                          }
                          
                        )
                      }
                      mx={{ base: 'auto', md: '0' }}
                      mt={{ base: '27px', md: '0' }}
                    />
                  </Link>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  )
})
