import { Icon, IconProps } from '@chakra-ui/react'
import { VFC } from 'react'

export const StartIcon: VFC<IconProps> = (props) => (
  <Icon viewBox="0 0 22 33" {...props}>
    <path
      d="M14.5 5.99996C15.9667 5.99996 17.1667 4.79996 17.1667 3.33329C17.1667 1.86663 15.9667 0.666626 14.5 0.666626C13.0334 0.666626 11.8334 1.86663 11.8334 3.33329C11.8334 4.79996 13.0334 5.99996 14.5 5.99996ZM9.70003 24.5333L11.0334 18.6666L13.8334 21.3333V29.3333H16.5V19.3333L13.7 16.6666L14.5 12.6666C16.2334 14.6666 18.9 16 21.8334 16V13.3333C19.3 13.3333 17.1667 12 16.1 10.1333L14.7667 7.99996C14.2334 7.19996 13.4334 6.66663 12.5 6.66663C12.1 6.66663 11.8334 6.79996 11.4334 6.79996L4.50003 9.73329V16H7.1667V11.4666L9.5667 10.5333L7.43336 21.3333L0.900032 20L0.366699 22.6666L9.70003 24.5333Z"
      fillOpacity="0.97"
    />
  </Icon>
)
