import React, { useState, useEffect } from 'react'
import { Box, Text, Flex, Divider, Button } from '@chakra-ui/react'
import CustomSwitch from './CustomSwitch'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SIDEBAR_COLOR_THEME } from '../../config/sidebarColorTheme'
import { useTranslation } from 'react-i18next'

const CookieSettings = ({ setIsManageCookieBannerOpen, setIsCookieBannerOpen }) => {
  const [analytics, setAnalytics] = useState(false)
  const { sidebarColorTheme } = usePlayerData()
  const { t, i18n } = useTranslation()

  const getCookieSettingsStyles = (sidebarColorTheme: string) => {
    switch (sidebarColorTheme) {
      case SIDEBAR_COLOR_THEME.BLACK:
        return {
          bgColorIcon: 'gray.800',
          contentColor: 'white',
          hoverIconColor: 'gray.700',
          bgColor: '#1A1A1A',
        }
      case SIDEBAR_COLOR_THEME.WHITE:
        return {
          bgColorIcon: 'white.800',
          contentColor: 'black.800',
          hoverIconColor: 'gray.300',
          bgColor: 'gray.200',
        }
      case SIDEBAR_COLOR_THEME.GRAY:
        return {
          bgColorIcon: 'gray.200',
          contentColor: 'black.800',
          hoverIconColor: 'white.900',
          bgColor: 'gray.400',
        }
      default:
        return {
          bgColorIcon: '#2067E3',
          contentColor: 'white',
          hoverIconColor: '#4A8DF2',
          bgColor: '#1A1A1A',
        }
    }
  }
  const currentStyle = getCookieSettingsStyles(sidebarColorTheme)

  useEffect(() => {
    const storedAnalytics = localStorage.getItem('analytics') === 'true'
    setAnalytics(storedAnalytics)
  }, [])

  const handleSave = () => {
    localStorage.setItem('analytics', analytics.toString())
    setIsCookieBannerOpen(false)
    setIsManageCookieBannerOpen(false)
  }

  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%"
      bg={currentStyle.bgColor}
      color="white"
      px={8}
      py={6}
      shadow="lg"
      zIndex="cookieModal"
      height={{ base: '380px', sm: '370px', md: '280px' }}
    >
      <Box height={{ md: '173px', sm: '240px', base: '260px' }}>
        <Box pb={4}>
          <Box height={{ md: '48px', base: '44px' }}>
            <Box pb={4}>
              <Text
                fontSize={{ base: '18px', lg: '20px' }}
                fontWeight="semibold"
                lineHeight="28px"
                color={
                  sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                    ? 'rgba(0,0,0,0.97)'
                    : 'rgba(255,255,255,0.97)'
                }
                letterSpacing="-0.01em"
                fontFamily="Montserrat"
              >
                {t('cookie.settingsTitle')}
              </Text>
            </Box>

            <Box display="flex" flexDirection="column" gap={2.5} w="full" h="auto">
              {/* Strictly Necessary */}
              <Flex gap={{ base: '16px', md: 6 }} align={{ base: 'left' }} flexDirection={{ base: 'column' }}>
                <Flex justify="space-between" align={{ md: 'center', base: 'baseline' }} w="full" gap={2.5}>
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    lineHeight="24px"
                    color={
                      sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                        ? 'rgba(0,0,0,0.97)'
                        : 'rgba(255,255,255,0.97)'
                    }
                    w="145px"
                    letterSpacing="-0.01em"
                    fontFamily="Montserrat"
                  >
                    {t('cookie.strictlyNecessary')}
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight="semibold"
                    lineHeight="20px"
                    color={sidebarColorTheme === 'gray' || sidebarColorTheme === 'white' ? 'black' : 'white'}
                    w="84px"
                    textAlign={{ md: 'center' }}
                    mt={{ base: -10, md: 0 }}
                    whiteSpace="nowrap"
                    fontFamily="Montserrat"
                  >
                    {t('cookie.alwaysActive')}
                  </Text>
                </Flex>
                <Text
                  fontSize="14px"
                  fontWeight="normal"
                  lineHeight="24px"
                  color={
                    sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                      ? 'rgba(0,0,0,0.76)'
                      : 'rgba(255,255,255,0.76)'
                  }
                  width={'fit-content'}
                  maxW={{ base: 'fit-content', md: '432px' }}
                >
                  {t('cookie.strictlyNecessaryDesc')}
                </Text>
              </Flex>

              <Divider
                borderColor={
                  sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                    ? 'rgba(0,0,0,0.24)'
                    : 'rgba(255,255,255,0.24)'
                }
              />

              {/* Analytics */}
              <Flex w="full" align={{ base: 'left' }} flexDirection={{ base: 'column' }}>
                <Flex
                  justify="space-between"
                  align={{ md: 'center', base: 'baseline' }}
                  w="full"
                  gap={{ base: 2, md: 2.5 }}
                  h={{ base: 'fit-content' }}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    lineHeight="24px"
                    color={
                      sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                        ? 'rgba(0,0,0,0.97)'
                        : 'rgba(255,255,255,0.97)'
                    }
                    letterSpacing="-0.01em"
                    w="145px"
                    fontFamily="Montserrat"
                  >
                    {t('cookie.analytics')}
                  </Text>
                  <CustomSwitch isChecked={analytics} onChange={setAnalytics} />
                </Flex>
                <Flex justify={'space-between'} w="full" alignItems={'center'}>
                  <Text
                    fontSize="14px"
                    fontWeight="normal"
                    lineHeight="24px"
                    color={
                      sidebarColorTheme === 'gray' || sidebarColorTheme === 'white'
                        ? 'rgba(0,0,0,0.76)'
                        : 'rgba(255,255,255,0.76)'
                    }
                  >
                    {t('cookie.analyticsDesc')}
                  </Text>
                  <Box
                    display={{ md: 'block', base: 'none' }}
                    py={1.5}
                    px={1}
                    w={{ base: '311px', md: i18n.language === 'ja' ? '110px' : '136px' }}
                  >
                    <Button
                      bg={currentStyle.bgColorIcon}
                      color={currentStyle.contentColor}
                      _active={{ bg: currentStyle.bgColorIcon }}
                      w={{ base: '311px', md: i18n.language === 'ja' ? '102px' : '128px' }}
                      _hover={{ bg: currentStyle.hoverIconColor }}
                      size="md"
                      onClick={handleSave}
                      px={3}
                      py={2.5}
                      height="36px"
                      sx={{
                        '&::before': {
                          content: 'none',
                          display: 'none',
                        },
                      }}
                    >
                      {t('cookie.saveSettings')}
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Save Settings Button */}
      <Flex
        display={{ md: 'none', base: 'center' }}
        justify={{ md: 'flex-end', base: 'center' }}
        h="48px"
        w="full"
        mt={{ base: 6, md: 0 }}
      >
        <Box py={1.5} px={1} w={{ base: '311px', md: i18n.language === 'ja' ? '110px' : '136px' }}>
          <Button
            bg={currentStyle.bgColorIcon}
            color={currentStyle.contentColor}
            _active={{ bg: currentStyle.bgColorIcon }}
            w={{ base: '311px', md: i18n.language === 'ja' ? '102px' : '128px' }}
            // _hover={{ bg: '#185abc' }}
            _hover={{ bg: currentStyle.hoverIconColor }}
            size="md"
            onClick={handleSave}
            px={3}
            py={2.5}
            height="36px"
            sx={{
              '&::before': {
                content: 'none',
                display: 'none',
              },
            }}
          >
            {t('cookie.saveSettings')}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default CookieSettings
