import { Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { memo, VFC } from 'react'
import { usePlayerData } from '../../../hooks/usePlayerData'
import { SIDEBAR_COLOR_THEME } from '../../../config/sidebarColorTheme'
export const LoadingIcon: VFC = memo(() => {
  const { sidebarColorTheme } = usePlayerData()

  const FACE_WIDTH = '15px' // 面の幅
  const BLUE = '#2067e3'
  const keyBaack = keyframes`
    from {transform: translateX(calc(${FACE_WIDTH} * 0))}
    to {transform: translateX(calc(${FACE_WIDTH} * -8))}
  `
  const keyFront = keyframes`
  from {transform: translateX(calc(${FACE_WIDTH} * -5))}
  to {transform: translateX(calc(${FACE_WIDTH} * 3))}
`
  const prefersReducedMotion = usePrefersReducedMotion()
  const animationBack = prefersReducedMotion ? undefined : `${keyBaack} infinite 2s linear`
  const animationFront = prefersReducedMotion ? undefined : `${keyFront} infinite 2s linear`

  /**
   *  面
   * */
  const Face = (props) => {
    return <Box position="absolute" top="0" h="12px" {...props} />
  }
  const getLandingStyles = (sidebarColorTheme: string) => {
    switch (sidebarColorTheme) {
      case SIDEBAR_COLOR_THEME.BLACK:
        return {
          iconColor: 'white.800',
          WHITE: 'rgba(255, 255, 255, 0.16)',
        }
      case SIDEBAR_COLOR_THEME.WHITE:
        return {
          iconColor: 'black.800',
          WHITE: 'gray.300',
        }
      case SIDEBAR_COLOR_THEME.GRAY:
        return {
          iconColor: 'white.900',
          WHITE: 'gray.500',
        }
      default:
        return {
          iconColor: '#2067e3',
          WHITE: 'rgba(255, 255, 255, 0.16)',
        }
    }
  }
  const currentStyle = getLandingStyles(sidebarColorTheme)

  return (
    <Box w="40px" h="12px" position="relative" overflow="hidden">
      <Box position="absolute" top="0" left="0" animation={animationBack}>
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 1)`} backgroundColor={currentStyle.WHITE} />
        <Face w={`calc(${FACE_WIDTH} * 3)`} left={`calc(${FACE_WIDTH} * 4)`} backgroundColor={currentStyle.WHITE} />
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 9)`} backgroundColor={currentStyle.WHITE} />
      </Box>
      <Box position="absolute" top="0" left="0" animation={animationFront}>
        <Face w={`calc(${FACE_WIDTH} * 1)`} left={`calc(${FACE_WIDTH} * 0)`} backgroundColor={currentStyle.iconColor} />
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 3)`} backgroundColor={currentStyle.iconColor} />
      </Box>
    </Box>
  )
})
