import { Box, useColorModeValue } from '@chakra-ui/react'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SIDEBAR_COLOR_THEME } from '../../config/sidebarColorTheme'

function CustomSwitch({ isChecked, onChange }) {
  const { sidebarColorTheme } = usePlayerData()
  const getLandingStyles = (sidebarColorTheme: string) => {
    switch (sidebarColorTheme) {
      case SIDEBAR_COLOR_THEME.BLACK:
        return {
          bgColor: 'gray.400',
          defaultBgColor: 'rgba(255, 255, 255, 0.24)',
        }
      case SIDEBAR_COLOR_THEME.WHITE:
        return {
          bgColor: 'white.800',
          defaultBgColor: 'gray.400',
        }
      case SIDEBAR_COLOR_THEME.GRAY:
        return {
          bgColor: 'gray.200',
          defaultBgColor: 'rgba(255, 255, 255, 0.24)',
        }
      default:
        return {
          bgColor: '#2067E3',
          defaultBgColor: 'rgba(255, 255, 255, 0.24)',
        }
    }
  }
  const currentStyle = getLandingStyles(sidebarColorTheme)
  return (
    <Box
      as="label"
      display="inline-flex"
      alignItems="center"
      cursor="pointer"
      position="relative"
      width="44px"
      height="48px"
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        style={{
          display: 'none',
        }}
      />
      <Box
        width="36px"
        height="20px"
        background={isChecked ? currentStyle.bgColor : currentStyle.defaultBgColor}
        borderRadius="full"
        position="relative"
        transition="all 0.3s"
        _after={{
          content: `""`,
          position: 'absolute',
          top: '1px',
          left: isChecked ? 'calc(100% - 18px - 1px)' : '1px',
          width: '18px',
          height: '18px',
          backgroundColor: isChecked ? '#292929' : '#1A1A1A',
          borderRadius: 'full',
          transition: 'all 0.3s',
        }}
      />
    </Box>
  )
}

export default CustomSwitch
